//
// Rowlink
// --------------------------------------------------

.table.rowlink,
.table .rowlink {
  td:not(.rowlink-skip) {
    cursor: pointer;

    a {
      color: inherit;
      font: inherit;
      text-decoration: inherit;
    }
  }
}

.table-hover.rowlink,
.table-hover .rowlink {
  tr:hover td {
    background-color: darken(@table-bg-hover, 15%);
  }
}
